<template>
	<div class="">
		<b-button v-can="'formsBuilder.create'" v-b-modal.new-form-modal variant="primary">
			New Form
		</b-button>
		<b-modal
			id="new-form-modal"
			ref="newFormModal"
			:title="isEdit ? 'Edit form' : 'Add new form'"
			ok-title="Save"
			centered
			cancel-variant="outline-secondary"
			@hidden="resetModal"
			@ok="handleOk"
		>
			<validation-observer ref="formRules">
				<b-form>
					<b-row>
						<!-- name -->
						<b-col cols="12">
							<b-form-group label="Name" label-for="name">
								<validation-provider #default="{ errors }" name="Name" rules="required">
									<b-form-input
										id="name"
										v-model="formData.name"
										:state="errors.length > 0 ? false : null"
										placeholder="Name"
									/>
									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-form-group>
						</b-col>

						<!-- type -->
						<b-col cols="12">
							<b-form-group label="Type" label-for="type">
								<validation-provider #default="{ errors }" name="Type" rules="required">
									<v-select
										id="type"
										v-model="formData.type"
										:clearable="false"
										:filterable="false"
										:searchable="false"
										:options="getTypes"
										:reduce="(o) => o.id"
										:state="errors.length > 0 ? false : null"
									/>
									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-form-group>
						</b-col>

						<!-- sector -->
						<b-col cols="12">
							<b-form-group label="Sector" label-for="type">
								<v-select
									id="type"
									v-model="sectorId"
									clearable
									:filterable="false"
									:searchable="false"
									:options="getSectors"
									:reduce="(o) => o.id"
								/>
							</b-form-group>
						</b-col>

						<!-- sub sector -->
						<b-col cols="12">
							<b-form-group label="Sub sector" label-for="type">
								<v-select
									id="type"
									v-model="subSectorId"
									clearable
									:filterable="false"
									:searchable="false"
									:options="getSubSectors(sectorId)"
									:reduce="(o) => o.id"
								/>
							</b-form-group>
						</b-col>

						<!-- Who -->
						<b-col cols="12">
							<b-form-group label="Who will fill the form" label-for="filler">
								<validation-provider #default="{ errors }" name="Type" rules="required">
									<v-select
										id="filler"
										v-model="formData.filler"
										:clearable="false"
										:filterable="false"
										:searchable="false"
										:options="getFillers"
										:reduce="(o) => o.id"
										:state="errors.length > 0 ? false : null"
									/>
									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-form-group>
						</b-col>

						<!-- Period -->
						<b-col cols="12">
							<b-form-group label="Form will be filled" label-for="period">
								<validation-provider #default="{ errors }" name="Type" rules="required">
									<v-select
										id="period"
										v-model="formData.period"
										:clearable="false"
										:filterable="false"
										:searchable="false"
										:options="getPeriods"
										:reduce="(o) => o.id"
										:state="errors.length > 0 ? false : null"
									/>
									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-form-group>
						</b-col>

						<!-- Stage -->
						<b-col cols="12">
							<b-form-group label="Stage" label-for="stage">
								<validation-provider #default="{ errors }" name="Stage" rules="required">
									<v-select
										id="stage"
										v-model="formData.stage"
										:clearable="false"
										:filterable="false"
										:searchable="false"
										:options="getStages"
										:reduce="(o) => o.id"
										:state="errors.length > 0 ? false : null"
									/>
									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-form-group>
						</b-col>

						<!-- description -->
						<b-col cols="12">
							<b-form-group label="Description" label-for="description">
								<b-form-textarea
									id="description"
									v-model="formData.description"
									type="text"
									placeholder="Description"
								/>
							</b-form-group>
						</b-col>

						<b-col cols="12">
							<b-form-checkbox v-model="formData.editable">Is form editable</b-form-checkbox>
							<b-form-checkbox v-model="formData.is_report">Is report</b-form-checkbox>
						</b-col>
					</b-row>
				</b-form>
			</validation-observer>
		</b-modal>
	</div>
</template>

<script>
import store from '@/store';
import { mapGetters } from 'vuex';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import vSelect from 'vue-select';
import { BFormInput, BFormGroup, BForm, BRow, BCol, BButton, BFormTextarea, BFormCheckbox } from 'bootstrap-vue';
import { required } from '@validations';

export default {
	components: {
		ValidationProvider,
		ValidationObserver,
		BFormInput,
		BFormGroup,
		BForm,
		BRow,
		BCol,
		BButton,
		BFormTextarea,
		BFormCheckbox,

		vSelect,
	},

	props: {
		data: {
			type: Object,
			default: () => ({
				description: '',
				name: '',
				type: '',
				filler: '',
				period: '',
				stage: '',
				editable: false,
				is_report: false,
			}),
		},
	},

	data() {
		return {
			required,
			subSectors: [],
			sectorId: '',
			subSectorId: '',
		};
	},

	computed: {
		formData() {
			return {
				...this.data,
			};
		},

		isEdit() {
			return this.data.name !== '';
		},

		...mapGetters('attributes', [
			'getFillers',
			'getStages',
			'getTypes',
			'getPeriods',
			'getSectors',
			'getSubSectors',
		]),
	},

	watch: {
		formData: {
			handler(val) {
				this.subSectorId = val.sub_sector_id;
				this.sectorId = val.sector_id;
			},
			deep: true,
		},
	},

	methods: {
		resetModal() {
			this.$emit('hidden');
		},

		handleOk(bvModalEvt) {
			// Prevent modal from closing
			bvModalEvt.preventDefault();
			// Trigger submit handler
			this.handleSubmit();
		},

		async handleSubmit() {
			const success = await this.$refs.formRules.validate();
			if (!success) return;

			if (!this.isEdit) {
				await store.dispatch('forms/addForm', {
					...this.formData,
					type: this.formData.type,
					period: this.formData.period,
					filler: this.formData.filler,
					stage: this.formData.stage,
					sector_id: this.sectorId,
					sub_sector_id: this.subSectorId,
				});
			} else {
				await store.dispatch('forms/editForm', {
					...this.data,
					...this.formData,
					sector_id: this.sectorId,
					sub_sector_id: this.subSectorId,
				});
			}

			// Hide the modal manually
			this.$nextTick(() => {
				this.$refs.newFormModal.toggle();
			});

			this.$emit('done');
		},
	},
};
</script>

<style lang="scss">
.vs__clear {
	padding-left: 8px;
	margin-top: -2px;
}
</style>
