<template>
	<div class="">
		<base-table ref="table" :headers="headers" :items="fetchForms" paginate table-top>
			<template #action>
				<FormNew v-can="'formsBuilder.create'" :data="formToEdit" @hidden="formToEdit = undefined" @done="refresh" />
			</template>

			<template #cell(type)="data">
				<span class="font-weight-bold"> {{ getType(data.value) }} </span>
			</template>

			<template #cell(actions)="data">
				<div class="text-nowrap">
					<b-button
						v-b-tooltip.hover.top="'Preview form'"
						variant="outline-secondary"
						class="btn-icon cursor-pointer mr-1"
						size="sm"
						@click="$router.push({ name: 'form-details', params: { id: data.item.id } })"
					>
						<feather-icon icon="EyeIcon" size="16" />
					</b-button>
					<b-button
						v-can="'formsBuilder.edit'"
						v-b-tooltip.hover.top="'Edit'"
						v-b-modal.new-form-modal
						variant="outline-primary"
						class="btn-icon cursor-pointer mr-1"
						size="sm"
						@click="editForm(data.item)"
					>
						<feather-icon icon="Edit3Icon" size="16" />
					</b-button>
				</div>
			</template>
		</base-table>

		<confirm-dailog ref="confirm"></confirm-dailog>
	</div>
</template>

<script>
import { VBTooltip, BButton } from 'bootstrap-vue';

import store from '@/store';
import { mapGetters } from 'vuex';
import ConfirmDailog from '@/components/ConfirmDailog.vue';
import BaseTable from '@/components/BaseTable.vue';
import FormNew from './new-form.vue';

export default {
	name: 'FormIndex',

	components: {
		BButton,

		FormNew,
		ConfirmDailog,
		BaseTable,
	},

	directives: {
		'b-tooltip': VBTooltip,
	},

	data: () => ({
		headers: [
			{ key: 'id', label: '#', sortable: true },
			{ key: 'name', sortable: true },
			{ key: 'type', sortable: true },
			{ key: 'description' },
			{ key: 'actions' },
		],

		formToEdit: undefined,
	}),

	computed: {
		...mapGetters('attributes', ['getTypes']),
	},

	methods: {
		getType(typeId) {
			return this.getTypes.find((t) => t.id === typeId)?.label ?? 'Unknown';
		},

		fetchForms(ctx, searchQuery) {
			return store.dispatch('forms/getForms', { ...ctx, searchQuery });
		},

		editForm(form) {
			this.formToEdit = form;
		},

		async deleteForm(form) {
			const confirm = await this.$refs.confirm.open('Delete form', 'Are you sure, delete this form?');
			if (confirm) {
				await store.dispatch('forms/deleteForm', form.id);
				this.refresh();
			}
		},

		refresh() {
			this.$refs.table.refresh();
		},
	},
};
</script>

<style lang="scss" scoped></style>
